import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  width: "28",
  height: "28",
  viewBox: "0 0 28 28",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<path fill=\"#212529\" d=\"M27.4238 8.0221L27.4238 0.559998L0.574028 0.559997L0.574027 8.0221L27.4238 8.0221Z\"></path><path fill=\"#212529\" d=\"M27.4238 17.8097L27.4238 10.3476L0.574043 10.3476L0.574043 17.8097L27.4238 17.8097Z\"></path><path fill=\"#212529\" d=\"M27.4238 27.44L27.4238 19.9779L0.574043 19.9779L0.574043 27.44L27.4238 27.44Z\"></path><circle cx=\"23.4474\" cy=\"4.29113\" r=\"1.64267\" fill=\"#FF7602\"></circle><circle cx=\"23.4474\" cy=\"14.0788\" r=\"1.64267\" fill=\"#FF7602\"></circle><circle cx=\"23.4474\" cy=\"23.7091\" r=\"1.64267\" fill=\"#FF7602\"></circle>", 6)
  ])))
}
export default { render: render }